export default {
    title : 'dark',
    colors: {
      headerHeight: '7.2rem',
      hue: 'var(--color-hue-dark)',
      baseColor: 'hsl(var(--color-hue-dark) 36% 57%)',
      baseColorSecond: 'hsl(var(--color-hue-dark) 65% 88%)',
      baseColorAlt: 'hsl(var(--color-hue-dark) 57% 53%)',
      titleColor: '#FFF',
      textColor: '#FFF',
      textColorLight: '#FFF',
      bodyColor: '#212121',
      bodyColorAlt: '#333',
      barLoadingColor:'hsl(244, 82%, 60%)',
      swiperThemeColor: 'blue',
      errorColor: 'hsl(244, 80%, 50%)',
      shadowPorCent: '0.36',
      shadowPorCentSuport: '8%',
      imgFilter: 'grayscale(500%) brightness(1000%) sepia(0) saturate(1000%) invert(0)',
    },
};
